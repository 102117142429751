import React from 'react';
import CanoeButton from '../../common/CanoeButton';

function Canoe() {
    return (
      <div className="dreamhouse-inner-content">
        <CanoeButton></CanoeButton>
      </div>
    );
  }
  
  export default Canoe;