import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../../../assets/icons/ArrowRight.svg';
import { ReactComponent as ArrowUp } from '../../../assets/icons/ArrowUp.svg';

const ProjectCard = ({ name, isSelected, tag, image, title, description }) => {
    const navigate = useNavigate();

    const handleBack = () => {
        if (isSelected) {
            navigate(`/`);
        } else {
            navigate(`/${name}`);
        }
    };

    const cardStyle = isSelected ? { backgroundColor: '#E0E0E0' } : {};

    return (
        <div className="project-card" style={cardStyle} onClick={handleBack}>
            <div className="project-card-head">
                <span className="project-card-tag">{tag}</span>
                <div className="project-selection-arrow">
                    {isSelected ? <ArrowUp /> : <ArrowRight /> }
                </div>
            </div>
            <div className="project-card-content">
                <img src={image} alt={title} className="project-card-image" />
                <div>
                    <h3 className="project-card-title">{title}</h3>
                    <p className="project-card-description">{description}</p>
                </div>
            </div>
        </div>
    );
};

export default ProjectCard;

