// SelectedProjects.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { RouteSwitch } from '../../../router';
import ProjectCard from '../SelectedProjectCard';
import './SelectedProjects.css';

const projects = [
  {
    name: 'cool-things',
    tag: '❤️‍🔥',
    image: process.env.PUBLIC_URL + '/concept.svg',
    title: 'Fun stuff',
    description: 'click to see some things ;)'
  },
  {
    name: 'f-fashion',
    tag: 'Professional Network',
    image: process.env.PUBLIC_URL + '/(f)world.svg',
    title: 'f.fashion',
    description: 'f.fashion is a professional network reimagining collaboration, job hunting, inspiration, and learning within the fashion industry'
  },
  {
    name: 'dreamhouse',
    tag: 'Fashion Brand',
    image: process.env.PUBLIC_URL + '/dream3.svg',
    title: 'Dreamhouse',
    description: 'Dreamhouse is a collection for a changing world.'
  },
  {
    name: 'Early',
    tag: 'Fashion Brand',
    image: process.env.PUBLIC_URL + '/early.png',
    title: 'Early Majority',
    description: 'An extension of my senior thesis.'
  },
  {
    name: 'malaika-fitness',
    tag: 'Health-Minded Media',
    image: process.env.PUBLIC_URL + '/mfit4code2.svg',
    title: 'Malaika Fitness',
    description: 'A confident identity and website for a health-minded media company.'
  },
  {
    name: 'canoe',
    tag: 'Professional Network',
    image: process.env.PUBLIC_URL + '/canoe4code3.svg',
    title: 'Canoe',
    description: 'Canoe is here to form life-changing connections and build fantastic stories.'
  }
];

const SelectedProjects = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 700px)` });
  const { pathname } = useLocation();

  return (
    <div className="project-list">
      {projects.map((project, index) => {
        const isCurrentProjectSelected = `/${project.name}` === pathname;
        const isShowingContent = isMobile && isCurrentProjectSelected;

        return (
          <div key={index}>
            <ProjectCard
              name={project.name}
              isSelected={isCurrentProjectSelected}
              tag={project.tag}
              image={project.image}
              title={project.title}
              description={project.description}
            />
            {isShowingContent && (
              <div className="content">
                <RouteSwitch />
              </div>
            )}
          </div>
        )
      })}
    </div>
  );
};

export default SelectedProjects;
