import React from 'react';
import './style.css';

function InfoCard({ title, subtitle, children }) {
    return (
      <div className="card-container">
        <div className="card-title">
          {title}
        </div>
        <div className="card-subtitle">
          {subtitle}
        </div>
        <div className="card-content">
          {children}
        </div>
      </div>
    );
  }
  
  export default InfoCard;