import React from 'react';
import './style.css';

function TagsCollection({ list = [] }) {
    return (
      <div className="tags-container">
        {list.map((item, index) => (
          <div className="tag-item" key={index}>
            {item}
          </div>
        ))}
      </div>
    );
  }
  
  export default TagsCollection;