import React from 'react';
import './ProjectNavbar.css';

const ProjectNavbar = () => {
  return (
    <div className="project-navbar">
      <a href="https://www.behance.net/collinbriggs" target="_blank" rel="noopener noreferrer">
        <button className="navbar-btn">Misc work</button>
      </a>
      <a href="mailto:collinbriggs19@gmail.com">
        <button className="navbar-btn2">Email me</button>
      </a>
      
      {/* <img className='cool-logo' src={`${process.env.PUBLIC_URL}/m garden.png`} alt="cool-logos" /> */}
      {/* <img className='cool-logo' src={`${process.env.PUBLIC_URL}/b-earth.png`} alt="cool-logos" /> */}
      {/* <img className='cool-logo' src={`${process.env.PUBLIC_URL}/concept.png`} alt="cool-logos" /> */}
    
    </div>
  );
};

export default ProjectNavbar;
