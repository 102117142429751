import React from 'react';
import DreamhouseButton from '../../common/DreamhouseButton';
import InfoCard from '../../common/InfoCard';
import './style.css'

function Dreamhouse() {
    return (
      <div className='dreamhouse-inner-content'>
        <div className='update-section'>
        <InfoCard
          title="Dreamhouse (may 2023)"
        >
          Dreamhouse is a collection for the changing world.

At the center is a deep-seated belief in the power of human ingenuity to shape our destiny. With Earth constantly changing and resources becoming scarce, it's more important than ever to be ready for whatever comes next.

Dreamhouse brings together classic silhouettes and technical functionality for the modern world. With waterproof, articulated, and gusseted designs, this collection offers sleek and structured protection against the elements. By incorporating recycled and organic materials, we help to drive human progression while reducing fashion's impact on the environment.

As the Earth constantly changes and resources become scarce, this collection offers a glimpse into the future of fashion - one that is advanced and sustainable, protecting both humans and the environment.
        </InfoCard>
      </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/DreamhousePics/newmood.png`} alt="Unknown flower, full blossom, c2018" />
        </div>
        <div>
        <DreamhouseButton>
            
        </DreamhouseButton>
    </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/DreamhousePics/welcome.png`} alt="Welcome" />
        </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/DreamhousePics/cords and locks and fabric.png`} alt="cord/ locks/ fabric" />
        </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/DreamhousePics/product page.png`} alt="Product" />
        </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/DreamhousePics/detail.png`} alt="Detailed look" />
        </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/DreamhousePics/Logo development.png`} alt="Logo Development" />
        </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/DreamhousePics/look1dev.png`} alt="Look #1 Development" />
        </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/DreamhousePics/lookoneill.png`} alt="Look #1 Illustration + Flats" />
        </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/DreamhousePics/look2.png`} alt="Look #2 Development" />
        </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/DreamhousePics/looktwoill.png`} alt="Look #2 Illustration + Flats" />
        </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/DreamhousePics/lookthreedev.png`} alt="Look #3 Development" />
        </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/DreamhousePics/lookthreeill.png`} alt="Look #3 Illustration + Flats" />
        </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/DreamhousePics/finalook.png`} alt="Final Looks" />
        </div>
        
        <div className="additional-logos">
            <div className="additional-logo-item">
                <img src={`${process.env.PUBLIC_URL}/FashionLooks/Collin_0068_SET2.jpg`} alt="Isabel Full" />
            </div>
            <div className="additional-logo-item">
                <img src={`${process.env.PUBLIC_URL}/FashionLooks/Collin_0108_SET2.jpg`} alt="Z Full" />
            </div>
            <div className="additional-logo-item">
            <img src={`${process.env.PUBLIC_URL}/FashionLooks/Collin_0143_SET2.jpg`} alt="Jaiden Full" />
            </div>
        </div>

      

        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}FashionLooks/Collin_0101_SET2.jpg`} alt="Isabel Portrait" />
        </div>
      


        <div className="additional-logos2">
            <div className="additional-logo-item2">
                <img src={`${process.env.PUBLIC_URL}/FashionLooks/Collin_0154_SET2.jpg`} alt="J detail" />
            </div>
            <div className="additional-logo-item">
                <img src={`${process.env.PUBLIC_URL}/FashionLooks/Collin_0130_SET2.jpg`} alt="Z detail" />
            </div>
            
        </div>




        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}FashionLooks/IMG_1907.jpeg`} alt="gang" />
        </div>

        <div className="additional-logos">
            <div className="additional-logo-item">
                <img src={`${process.env.PUBLIC_URL}/FashionLooks/IMG_1831.jpg`} alt="Isabel Full" />
            </div>
            <div className="additional-logo-item">
                <img src={`${process.env.PUBLIC_URL}/FashionLooks/IMG_1867.jpg`} alt="Isabel Full" />
            </div>
            <div className="additional-logo-item">
            <img src={`${process.env.PUBLIC_URL}/FashionLooks/IMG_1885.jpg`} alt="Isabel Full" />
            </div>
        </div>

        <div className="additional-logos2">
            <div className="additional-logo-item2">
                <img src={`${process.env.PUBLIC_URL}/FashionLooks/IMG_1838.jpg`} alt="Isabel Full" />
            </div>
            <div className="additional-logo-item">
                <img src={`${process.env.PUBLIC_URL}/FashionLooks/IMG_1888.jpg`} alt="Isabel Full" />
            </div>
            
        </div>
        <div className="additional-logos2">
            <div className="additional-logo-item2">
                <img src={`${process.env.PUBLIC_URL}/FashionLooks/IMG_1892.jpg`} alt="Isabel Full" />
            </div>
            <div className="additional-logo-item">
                <img src={`${process.env.PUBLIC_URL}/FashionLooks/IMG_1863.jpg`} alt="Isabel Full" />
            </div>
            
        </div>

        <div className="additional-logos">
            <div className="additional-logo-item">
                <img src={`${process.env.PUBLIC_URL}/FashionLooks/IMG_1876.jpg`} alt="Isabel Full" />
            </div>
            <div className="additional-logo-item">
                <img src={`${process.env.PUBLIC_URL}/FashionLooks/IMG_1861.jpg`} alt="Isabel Full" />
            </div>
            <div className="additional-logo-item">
            <img src={`${process.env.PUBLIC_URL}/FashionLooks/IMG_1913.jpg`} alt="Isabel Full" />
            </div>
        </div>

      </div>
    );
  }
  
  export default Dreamhouse;