import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ProfileCard.css';
import { ReactComponent as ArrowRight } from '../../../assets/icons/ArrowRight.svg';
import { ReactComponent as ArrowUp } from '../../../assets/icons/ArrowUp.svg';

const ProfileCard = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isSelected = pathname === '/about';
  const cardStyle = isSelected ? { backgroundColor: '#E0E0E0' } : {};

  const handleNavigate = () => {
    if (isSelected) {
        navigate(`/`);
    } else {
        navigate(`/about`);
    }
  };

  return (
      <div className="profile-details" style={cardStyle} onClick={handleNavigate}>
        <div className="profile-image">
        <img src={`${process.env.PUBLIC_URL}/IMG_1004.jpg`} alt="Profile"/>
        </div>
        <h2 className="profile-name">Collin Briggs</h2>
        <h3 className="profile-title">Design Generalist*</h3>
        <h4 className="profile-metatitle"> (*aspiring motorcycle mechanic)</h4>
        <p className="profile-description">
        As a design generalist, I am passionate about creating innovative design systems that not only advance human development but also contribute positively to the well-being of our planet.</p>
        <div className="project-card-arrow">
          {isSelected ? <ArrowUp /> : <ArrowRight /> }
        </div>
      </div>
    
  );
};

export default ProfileCard;

