import React from 'react';
import InfoCard from '../../common/InfoCard';
import './HomePage.css';


function HomePage() {


  return (
    <div className='inner-content-about'>
      <div className="about">
        <img className="mind-map" src={`${process.env.PUBLIC_URL}/Screenshot 2023-06-14 at 2.22.57 PM.png`} alt="Mind Map" />
      </div>
      <div className='update-section'>
        <InfoCard
          title="Update (05162023 cb)"
        >
          This website serves not only as my portfolio, but my journey into learning React.js, with both the design and development accomplished by myself and a friend. As you navigate through the site, you may encounter projects that are either missing or incomplete; this is because the site is currently under construction. In the meantime, please follow the provided link to view the available projects. I am actively seeking employment opportunities in fields such as technical design, apparel innovation, branding, and design engineering.
        </InfoCard>
      </div>

      <div className='update-section'>
        <InfoCard
          title="Expert tip"
        >
click the arrows to navigate to different pages... sorry page loads are v slow
        </InfoCard>
      </div>

    </div>
    
  );
}

export default HomePage;
