import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './components/pages/HomePage';
import MalaikaFitness from './components/pages/MalaikaFitness';
import About from './components/pages/About';
import Dreamhouse from './components/pages/Dreamhouse';
import Canoe from './components/pages/Canoe';
import Fashion from './components/pages/f.fashion';
import Early from './components/pages/Early';
import Cool from './components/pages/Cool';

export const RouteSwitch = () => (
  <Suspense>
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path='/malaika-fitness' element={<MalaikaFitness />} />
      <Route path='/about' element={<About />} />
      <Route path='/dreamhouse' element={<Dreamhouse />} />
      <Route path='/canoe' element={<Canoe />} />
      <Route path='/f-fashion' element={<Fashion />} />
      <Route path='/early' element={<Early />} />
      <Route path='/cool-things' element={<Cool />} />
    </Routes>
  </Suspense>
);
