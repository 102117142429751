import React from 'react';
import InfoCard from '../../common/InfoCard';

function Early() {
    return (
      <div className='dreamhouse-inner-content'>
        <div className='update-section'>
        <InfoCard
          title="Early Majority"
        >An extension of my senior collection.
        </InfoCard>
      </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/collection extension/secondcollection_Page_01.png`} alt="Unknown flower, full blossom, c2018" />
        </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/collection extension/secondcollection_Page_02.png`} alt="Unknown flower, full blossom, c2018" />
        </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/collection extension/secondcollection_Page_03.png`} alt="Unknown flower, full blossom, c2018" />
        </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/collection extension/secondcollection_Page_04.png`} alt="Unknown flower, full blossom, c2018" />
        </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/collection extension/secondcollection_Page_05.png`} alt="Unknown flower, full blossom, c2018" />
        </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/collection extension/secondcollection_Page_06.png`} alt="Unknown flower, full blossom, c2018" />
        </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/collection extension/secondcollection_Page_07.png`} alt="Unknown flower, full blossom, c2018" />
        </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/collection extension/secondcollection_Page_08.png`} alt="Unknown flower, full blossom, c2018" />
        </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/collection extension/secondcollection_Page_09.png`} alt="Unknown flower, full blossom, c2018" />
        </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/collection extension/secondcollection_Page_10.png`} alt="Unknown flower, full blossom, c2018" />
        </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/collection extension/secondcollection_Page_11.png`} alt="Unknown flower, full blossom, c2018" />
        </div>
        <div className="dreamhouse-about">
          <img className="dreamhouse-image" src={`${process.env.PUBLIC_URL}/collection extension/secondcollection_Page_12.png`} alt="Unknown flower, full blossom, c2018" />
        </div>
        

      </div>
    );
  }
  
  export default Early;