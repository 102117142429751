import React from 'react';
import { ReactComponent as ArrowOut } from '../../../assets/icons/ArrowOut.svg';

const LINKS = [
    {
      label: 'View project in all its glory',
      subtitle: '"CLiCk tHe ArRoW"',
      link: 'https://www.figma.com/file/0GS0qAOju7gEUJg7HIbzAF/MALAIKA-FITNESS-WEBSITE?node-id=1%3A3&t=9BG27sunOLKSbLp8-1',
    }
  ]

  function MalaikaFitButton() {
    return (
      <div className="right-sidebar-container">
        {LINKS.map((item, index) => (
          <div className="sidebar-item" key={index}>
            <div>
              <div className="sidebar-item-label">{item?.label}</div>
              {item?.subtitle && (
                <div className="sidebar-item-subtitle">
                  {item.subtitle}
                </div>
              )}
            </div>
            <a className="arrow-out" href={item?.link}>
              <ArrowOut />
            </a>
          </div>
        ))}
      </div>
    );
  }
  
  export default MalaikaFitButton;