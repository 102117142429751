import React from 'react';
import './style.css';

function Cool() {
    return (
        <div className='inner-main-content'>
            <div className="my-video">
                <video width="" height="" controls>
                    <source src="/collinpose/collinpose1.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <video width="" height="" controls>
                    <source src="/collinpose/collinpose2.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <video width="" height="" controls>
                    <source src="/collinpose/collinpose3.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>

            <div className="additional-logos">
            <div className="additional-logo-item">
                <img src={`${process.env.PUBLIC_URL}/larahat/IMG_2029.jpg`} alt="Portrait" />
            </div>
            <div className="additional-logo-item">
                <img src={`${process.env.PUBLIC_URL}/larahat/IMG_2030.jpg`} alt="Profile" />
            </div>
            <div className="additional-logo-item">
            <img src={`${process.env.PUBLIC_URL}/larahat/IMG_2032.jpg`} alt="Back" />
            </div>
        </div>
        <div className="dreamhouse-about">
          <img className="coder-image" src={`${process.env.PUBLIC_URL}/Untitled_Artwork 65.png`} alt="f(dot)fashion web app" />
        </div>
        </div>
    );
}

export default Cool;
