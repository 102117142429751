// App.js

import React from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import ProjectNavbar from './components/common/ProjectNavbar';
import ProfileCard from './components/common/ProfileCard';
// import SayHello from './components/common/SayHello';
// import RightSidebar from './components/common/RightSidebar';
import SelectedProjects from './components/common/SelectedProjects';
import './App.css';
import { RouteSwitch } from './router';



function App() {
  const isMobile = useMediaQuery({ query: `(max-width: 700px)` });
  const { pathname } = useLocation();
  const isShowingAbout = isMobile && pathname === '/about'
  const isShowingHome = isMobile && pathname === '/'

  return (
    <div className="App">
     
      <div className="App-inner">
        <div className="sidebar">
          <ProjectNavbar />
          <ProfileCard />
          {/* <SayHello /> */}
          {(isShowingHome || isShowingAbout) && (
            <div className="content">
              <RouteSwitch />
            </div>
          )}
          <div className="nav">
            <SelectedProjects />
          </div>
          {/* {isShowingAbout && (
            <RightSidebar />
          )} */}
        </div>
        {!isMobile && (
          <div className="content">
            <RouteSwitch />
          </div>
        )}
      </div>
      
    </div>
  );
}

export default App;
