import React from 'react';
import './style.css';
import { ReactComponent as ArrowOut } from '../../../assets/icons/ArrowOut.svg';

const LINKS = [
  {
    label: 'Fashion, Branding, Experiments',
    subtitle: 'Click here to view past projects',
    link: 'https://www.behance.net/collinbriggs',
  },
  {
    label: 'Dribbble',
    subtitle: 'Product-y stuff is here',
    link: 'https://dribbble.com/collinb19',
  },
  {
    label: 'Linkedin',
    link: 'https://www.linkedin.com/in/collinbriggs/',
  },
  {
    label: 'Twitter',
    link: 'https://twitter.com/outlawcollin',
  },
  {
    label: 'Git',
    link: 'https://github.com/outlawcollin',
  },
  {
    label: 'Resume',
    subtitle: '05232023',
    link: 'https://drive.google.com/file/d/1GKTqJ5MNmlwOvn8KrO1aUxhGidHOogmv/view?usp=sharing',
  },
  {
    label: 'Email',
    subtitle: 'collin@whitecat.farm',
    link: 'mailto:collin@whitecat.farm',
  }
]

function RightSidebar() {
    return (
      <div className="right-sidebar-container">
        {LINKS.map((item, index) => (
          <div className="sidebar-item" key={index}>
            <div>
              <div className="sidebar-item-label">{item?.label}</div>
              {item?.subtitle && (
                <div className="sidebar-item-subtitle">
                  {item.subtitle}
                </div>
              )}
            </div>
            <a className="arrow-out" href={item?.link} target="_blank" rel="noopener noreferrer">
              <ArrowOut />
            </a>
          </div>
        ))}
      </div>
    );
  }
  
  export default RightSidebar;