import React from 'react';
import InfoCard from '../../common/InfoCard';
import WhiteCard from '../../../assets/img/pinkmf.svg';
import GreenCard from '../../../assets/img/LIMEG.svg';
import OragngeCard from '../../../assets/img/OBlue.svg';
import GreyCard from '../../../assets/img/Stonel.svg';
import RedCard from '../../../assets/img/EPINK.svg';
import SubCard from '../../../assets/img/creamgreen.svg';
import MalaikaFitButton from '../../common/MalaikaFitButton';

import './style.css';

function MalaikaFitness() {
  return (
    <div className="inner-content">

        <div className="additional-main-logo">
            <img src={WhiteCard} alt='Malaika Fitness Main Logo - White/Green' />
        </div>

        <div className="additional-logos2">
            <div className="additional-logo-item2">
                <img src={`${process.env.PUBLIC_URL}/MalaikaFitnessImages/Dolly5.png`} alt="J detail" />
            </div>
            <div className="additional-logo-item">
                <img src={`${process.env.PUBLIC_URL}/MalaikaFitnessImages/texts.png`} alt="Z detail" />
            </div>
            </div>

        <div className="additional-logos">
            <div className="additional-logo-item">
                <img src={OragngeCard} alt='Malaika Fitness Main Logo - Blue/Orange' />
            </div>
            <div className="additional-logo-item">
                <img src={GreenCard} alt='Malaika Fitness Secondary Logo - Green/Orange' />
            </div>
            <div className="additional-logo-item">
                <img src={GreyCard} alt='Malaika Fitness Main Logo - Cream/Grey' />
            </div>
            <div className="additional-logo-item">
                <img src={RedCard} alt='Malaika Fitness Secondary Logo - Cream/Hot Pink' />
            </div>
    </div>
        <div className="subscribe-section"> 
            <div className="subscribe-inner-section"> 
                <img src={SubCard} alt='Subscribe Component' />
        </div>
    </div>

    <div>
        <MalaikaFitButton>
            
        </MalaikaFitButton>
    </div>







        <div className="information-section">
            <InfoCard
              title="Deeper Information"
            >
                <div className="deeper-information-description">
                    Conceived by professional photographer and fitness trainer Lara Cox and designed by me. Malaika Fitness is a new take on health and nutrition media.
                    Over the past couple years “health advice” has been all the rage, but who is right? The experts? TikTok influencers? Well they all can be “right” if their information is properly sourced. mFit is a media company that prides itself on thoughtful, engaging information that is always backed by a well accredited source, in a format that is quick and fun to read.
                </div>
                <div className="deeper-information-credits">
                    Credits:<br /><br />

                    Malaika Fitness Editor:<br />
                    <u>Lara Cox</u><br /><br />

                    Creative Direction:<br />
                    <u>Collin Briggs</u>
                </div>
            </InfoCard>
            <div></div>
        </div>
    </div>
  );
}

export default MalaikaFitness;
