import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InfoCard from '../../common/InfoCard';
import TagsCollection from '../../common/TagsCollection';
import RightSidebar from '../../common/RightSidebar';

import './style.css';

const GOOD_LIST = [
  'Branding',
  'Technical design',
  'Ps (inter.)',
  'Ai (adv.)',
  'Figma (inter.)',
  'Blender (beg.)',
  'Creative Concepting',
  'UI design',
  'Mixed method research',
  'CLO3d (inter.)',
  'Designing work around what matters',
  'Leading',
];

const BETTER_LIST = [
  'HTML',
  'CSS',
  'React.js',
  'Javascript',
  'Frontend development',
  'UI engineering',
  'Designing for culture',
  'Management skills',
];

const INTEREST_LIST = [
  'Design',
  'Branding',
  'Technology',
  'Health',
  'Gardening',
  'Perfomance knits (building perfomance knit factory or brand)',
  'Community',
  'Engines',
  'Motorcycles (motorsport graphics, protective armor)',
  'Technical clothing (outerwear, element protection, Arcteryx meets motorcycles)',
  'The advancement of the planet',
  'Future materials (textiles, 3d knits, military application)',
  'Business building',
  'Entrepreneurship',
  'Creative websites',
  'AI'
];

const SLIDES = [
  `${process.env.PUBLIC_URL}/automatic slideshow/flowerpink2.jpg`,
  `${process.env.PUBLIC_URL}/automatic slideshow/flowerpink.png`,
  `${process.env.PUBLIC_URL}/automatic slideshow/collin3.png`,
  `${process.env.PUBLIC_URL}/automatic slideshow/IMG_6177.jpeg`,
  `${process.env.PUBLIC_URL}/automatic slideshow/IMG_1757.jpeg`,
  `${process.env.PUBLIC_URL}/automatic slideshow/IMG_1790.jpeg`,
  `${process.env.PUBLIC_URL}/automatic slideshow/IMG_1791.jpeg`,
  `${process.env.PUBLIC_URL}/automatic slideshow/IMG_1793.jpeg`,
  `${process.env.PUBLIC_URL}/automatic slideshow/IMG_1819.jpeg`,
  `${process.env.PUBLIC_URL}/automatic slideshow/IMG_1820.jpeg`,
  `${process.env.PUBLIC_URL}/automatic slideshow/charlie4.png`
]

function About() {
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div className='about-page'>
      <div className="left-main">
        <Slider {...settings}>
          {SLIDES.map((slideUrl, index) => (
            <div className='slideshow' key={index}>
              <img className="automatic-slideshow" src={slideUrl} alt="Slideshow Images" />
            </div>
          ))}
        </Slider>
        <div className='info-section'>
          <div className='info-section-inner'>
            <InfoCard
              title="I’m passionate about this rn (041523)"
              subtitle="(passions are always subject to change)"
            >
              At this moment in my life, my passion lies in the diverse world of design and innovation. As a design generalist, I thrive on exploring various fields and am not confined to just one area of interest. My skillset in design is constantly expanding, as I am actively learning more technical aspects such as coding and web development. My ultimate goal is to contribute something truly unique and valuable to the world, whether that means collaborating with a team of forward-thinking designers and engineers to develop groundbreaking software, pushing the boundaries of material science in the realm of textiles, or even crafting custom motorcycles that bring sheer joy to their owners. Embracing a multifaceted approach allows me to combine my passions and talents in pursuit of creating a better tomorrow.
            </InfoCard>
            <InfoCard
              title="My end goal (041523)"
              subtitle="(goals are always subject to change)"
            >
              Ultimately, my end goal is to provide for the ones I love, as I believe that life is truly about the people and the community behind it. This concept has always intrigued me, leading me to frequently ponder, "How can I create a product or service that profoundly resonates with individuals?" By doing so, I aim to effectively bring joy, foster learning, and encourage personal growth in others. Beyond that, owning a plot of land in the mountains, where I can establish a summer camp for children to create unforgettable experiences. Additionally, pursuing a career as a motorcycle mechanic where I can combine my passion for craftsmanship with my desire to help others.
            </InfoCard>
          </div>
          <div className='info-section-inner'>
            <InfoCard
              title="I’m good at"
              subtitle="(skills are always subject to change)"
            >
              <TagsCollection list={GOOD_LIST} />
            </InfoCard>
            <InfoCard
              title="Things I’m getting better at"
              subtitle="(always subject to change)"
            >
              <TagsCollection list={BETTER_LIST} />
            </InfoCard>
            <InfoCard
              title="I’m interested in"
              subtitle="(interests are always subject to change)"
            >
              <TagsCollection list={INTEREST_LIST} />
            </InfoCard>
          </div>
        </div>
      </div>
      <div className="right-sidebar">
        <RightSidebar />
      </div>
    </div>
  );
}
  
export default About;