import React from 'react';
import InfoCard from '../../common/InfoCard';
import './style.css'

function Fashion() {
    return (
      <div className='dreamhouse-inner-content'>
      <div className='update-section'>
        <InfoCard
          title="Update (06142023 cb)"
        >
          f.fashion is a professional network reimagining collaboration, job hunting, inspiration, and learning within the fashion industry. 
        </InfoCard>
      </div>
      <div className="dreamhouse-about">
          <img className="coder-image" src={`${process.env.PUBLIC_URL}/Untitled_Artwork 64.png`} alt="f(dot)fashion web app" />
        </div>
      <div className="dreamhouse-about">
          <img className="coder-image" src={`${process.env.PUBLIC_URL}/f.fashionfirstlook.png`} alt="f(dot)fashion web app" />
        </div>
    </div>
    );
  }
  
  export default Fashion;