import React from 'react';
import { ReactComponent as ArrowOut } from '../../../assets/icons/ArrowOut.svg';

const LINKS = [
    {
      label: 'View interactive moodboard',
      subtitle: 'this is an experiment',
      link: '',
    }
  ]

  function DreamhouseButton() {
    return (
      <div className="right-sidebar-container">
        {LINKS.map((item, index) => (
          <div className="sidebar-item" key={index}>
            <div>
              <div className="sidebar-item-label">{item?.label}</div>
              {item?.subtitle && (
                <div className="sidebar-item-subtitle">
                  {item.subtitle}
                </div>
              )}
            </div>
            <a className="arrow-out" href={item?.link}>
              <ArrowOut />
            </a>
          </div>
        ))}
      </div>
    );
  }
  
  export default DreamhouseButton;